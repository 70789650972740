/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');
var userHasRole = require('common/util/userHasRole');

var GetAllEntityLookups = require('../../../depository/common/services/GetAllEntityLookups');
var GetAllLicenseTypes = require('../../../non-depository/common/services/GetAllLicenseTypes');
var GetAllOrgLicenseLookups = require('../../../securities/common/services/GetAllOrgLicenseLookups');

var GetComplaint = require('../services/GetComplaint');
var GetAllComplaintLookups = require('../services/GetAllComplaintLookups');

var GetContactsByEntityAndDepartment = require('../../common/services/GetContactsByEntityAndDepartment');

var ConSerComplaintDashboardView = require('../views/ConSerComplaintDashboardView');

module.exports = function(complaintId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetComplaint(complaintId),
    GetAllComplaintLookups(),
    GetUsersForRole('CS - User'),
    GetAllEntityLookups(),
    GetAllLicenseTypes(),
    GetAllOrgLicenseLookups()
  )
    .done(function(complaint, lookups, caseManagers, entityLookups, nondepLicenseTypes, orgLicenseLookups) {
      // call GetContactsByEntityAndDepartment to get lsit of contacts associated with entity
      GetContactsByEntityAndDepartment({
        entityId: complaint.entityId,
        departmentId: complaint.departmentId
      }).done(function(contacts) {
        NavUtils.navigate('conser/complaint/' + complaintId + '/dashboard');
        Scaffold.content.show(
          new ConSerComplaintDashboardView({
            model: new Backbone.Model({
              complaint: complaint,
              contacts: contacts,
              openCloseHistory: complaint.openCloseHistory,
              companyResponses: lookups.companyResponses,
              complaintCode1: lookups.complaintCode1,
              complaintCode2: lookups.complaintCode2,
              complaintCode3: lookups.complaintCode3,
              contactMethods: lookups.contactMethods,
              individualTypes: lookups.individualTypes,
              productTypes: lookups.productTypes,
              referrals: lookups.referrals,
              complaintStatuses: lookups.complaintStatuses,
              weights: lookups.weights,
              departments: lookups.departments,
              departments2: lookups.departments2,
              complaintTypes: lookups.complaintType,
              complaintIssues: lookups.complaintIssues,
              serviceMemberStatuses: lookups.serviceMemberStatuses,
              caseManagers: caseManagers,
              depEntityTypes: entityLookups.entityTypes,
              nondepLicenseTypes: nondepLicenseTypes,
              secLicenseTypes: orgLicenseLookups.orgLicenseType,
              isAdministrator: userHasRole('CS - Administrator')
            })
          })
        );
        NProgress.done();
      });
    })
    .fail(function() {
      NProgress.done();
    });
};
