/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var SelectFieldView = require('common/views/SelectFieldView');

var ConSerInternalSidebarNavView = require('./ConSerInternalSidebarNavView');
var SearchConSerComplaints = require('../services/SearchConSerComplaints');

require('../styles/ConSerComplaintSearchView.css');
var tmplConSerComplaintSearchView = require('../templates/ConSerComplaintSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintSearchView,

  className: 'con-ser-complaint-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchForm: 'form',
    searchResultsContainer: '.search-results-container',
    departmentSelect: '.department', // Entity Type 1 dropdown
    entityType2Container: '.entity-type-2-container',
    dateOpenedRange: '.date-opened-range',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary',
    statusDraft: 'option[value="4"]'
  },

  regions: {
    sideBar: '@ui.sideBar',
    entityType2Container: '@ui.entityType2Container',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.departmentSelect': 'onChangeDepartment',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.ui.dateOpenedRange.datepicker();

    this.ui.statusDraft.hide();

    this.showChildView('sideBar', new ConSerInternalSidebarNavView());

    this.entityType2Dropdown = new SelectFieldView({
      isDisabled: true,
      isRequired: false,
      name: 'entityType2Id',
      collection: new Backbone.Collection()
    });
    this.showChildView('entityType2Container', this.entityType2Dropdown);

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'individualName',
          label: 'Individual Name',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'caseManager',
          label: 'Case Manager',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'complainantName',
          label: 'Complainant Name',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'complaintType',
          label: 'Complaint Type',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'department',
          label: 'Entity Type 1',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'entityType2',
          label: 'Entity Type 2',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'weight',
          label: 'Level',
          cell: 'string',
          headerCell: 'custom',
          width: 6,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 6,
          editable: false
        },
        {
          name: 'initialOpenedDate',
          label: 'Date Opened',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var complaintId = model.get('complaintId');
              return '#conser/complaint/' + complaintId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 6,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Complaints Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onChangeDepartment: function() {
    var departmentId = this.ui.departmentSelect.val();
    var entityType2DropdownOptions = [];
    switch (departmentId) {
      case '1000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('nonDepLicenseTypes'), 'name');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      case '2000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('depEntityTypes'), 'type');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      case '3000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('secLicenseTypes'), 'type');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      case '4000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('conserLicenseTypes'), 'description');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      default:
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(true);
        break;
    }
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;

    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(function() {
        var data = Syphon.serialize(self, {
          inputReaders: Syphon.SearchInputReaderSet
        });
        // If no inputs were given, do not perform a search.
        if (
          !data.caseNumber &&
          !data.caseManager &&
          !data.entityName &&
          !data.individualName &&
          !data.departmentId &&
          !data.entityType2Id &&
          !data.weightId &&
          !data.statusId &&
          !data.initialOpenedDateStart &&
          !data.initialOpenedDateEnd &&
          !data.complainantPhoneNumber &&
          !data.complaintTypeId &&
          !data.complainantName
        ) {
          return;
        }
        self.searchSummaryView.reset();

        var l = Ladda.create(self.ui.search[0]);
        l.start();
        var body = bodybuilder();

        if (data.caseNumber) {
          body.query('wildcard', 'caseNumber', data.caseNumber + '*');
        }

        if (data.caseManager) {
          body.query('term', 'caseManager', data.caseManager);
        }

        if (data.complaintTypeId) {
          body.query('term', 'complaintTypeId', data.complaintTypeId);
        }

        if (data.entityName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'entityName', {
              query: data.entityName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
            b.orQuery('match', 'entityName.phonetic', data.entityName);
            return b;
          });
        }

        if (data.individualName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'individualName', {
              query: data.individualName,
              fuzziness: 'AUTO'
            });
            b.orQuery('wildcard', 'individualName', data.individualName.toLowerCase() + '*');
            return b;
          });
        }

        if (data.departmentId) {
          body.query('term', 'departmentId', data.departmentId);
        }

        if (data.entityType2Id) {
          body.query('term', 'entityType2Id', data.entityType2Id);
        }

        if (data.weightId) {
          body.query('term', 'weightId', data.weightId);
        }

        if (data.statusId) {
          body.query('term', 'statusId', data.statusId);
        }

        var initialOpenedDateStart = moment(data.initialOpenedDateStart, 'MM/DD/YYYY'),
          initialOpenedDateEnd = moment(data.initialOpenedDateEnd, 'MM/DD/YYYY');

        if (data.initialOpenedDateStart && data.initialOpenedDateEnd) {
          body.query('bool', function(b) {
            b.andQuery('range', 'initialOpenedDate', {
              gte: initialOpenedDateStart.format('YYYY-MM-DD')
            });
            b.andQuery('range', 'initialOpenedDate', {
              lte: initialOpenedDateEnd.format('YYYY-MM-DD')
            });
            return b;
          });
        } else {
          if (data.initialOpenedDateStart) {
            body.query('range', 'initialOpenedDate', {
              gte: initialOpenedDateStart.format('YYYY-MM-DD')
            });
          }
          if (data.initialOpenedDateEnd) {
            body.query('range', 'initialOpenedDate', {
              lte: initialOpenedDateEnd.format('YYYY-MM-DD')
            });
          }
        }

        if (data.complainantPhoneNumber) {
          var cleanComplainantPhoneNumber = data.complainantPhoneNumber.replace(/[^0-9]/g, '');
          body.query('wildcard', 'complainantPhoneNumber', cleanComplainantPhoneNumber + '*');
        }

        if (data.complainantName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'complainantName', {
              query: data.complainantName,
              fuzziness: 'AUTO'
            });
            b.orQuery('wildcard', 'complainantName', data.complainantName.toLowerCase() + '*');
            return b;
          });
        }

        body.size(self.model.get('searchLimit'));
        var bodyObj = body.build();
        // Keep for testing
        // console.log(JSON.stringify(bodyObj, null, 2));
        SearchConSerComplaints(bodyObj)
          .done(function(data) {
            l.stop();
            self.searchResults.collection.fullCollection.reset(data.hits);
            self.searchSummaryView.display(data);
          })
          .fail(function(err) {
            l.stop();
          });
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    this.entityType2Dropdown.collection.reset();
    this.entityType2Dropdown.setDisabled(true);
  },

  formatDropdown: function(options, fieldName) {
    return _.map(options, function(option) {
      return { value: option.id, text: option[fieldName] };
    });
  }
});
