/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var _ = require('underscore');
var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');

require('../styles/ConSerComplaintFormComplaintInformationView.css');

var tmplConSerComplaintFormComplaintInformationView = require('../templates/ConSerComplaintFormComplaintInformationView.hbs');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormComplaintInformationView,

  className: 'conser-complaint-form-complaint-information-view',

  ui: {
    complaintInformationForm: '.complaint-information-form',
    fileContainer: '.file-container',
    initialInvestmentDate: '.initial-investment-date',
    lastInvestmentDate: '.last-investment-date'
  },

  regions: {
    fileContainer: '@ui.fileContainer'
  },

  onBeforeShow: function() {

    var documents = modelGet(this.model, 'documents', []);

    this.fileUploadView = new FileUploadView({
      collection: _.isEmpty(documents) ? makeUploadCollection([]) : new FileUploadCollection(documents),
      isReadOnly: false,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 40003
      }
    });
    this.showChildView('fileContainer', this.fileUploadView);

    this.ui.initialInvestmentDate.datepicker();

    this.ui.lastInvestmentDate.datepicker();
    
    Syphon.deserialize(this, this.model.toJSON());

  },

  validate: function(config) {

    this.ui.complaintInformationForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.complaintInformationForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.complaintInformationForm);
    }

    var validationPromises = [this.ui.complaintInformationForm.parsley().whenValidate()];

    return $.when.apply(this, validationPromises);
    
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.documents = this.fileUploadView.collection.toJSON();
    return formData;
  }
});
