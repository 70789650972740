/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitReviewCorrespondenceTask = require('../../services/SubmitReviewCorrespondenceTask');

var tmplConSerReviewCorrespondenceTaskView = require('./ConSerReviewCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerReviewCorrespondenceTaskView,

  className: 'con-ser-review-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    hasFinDocs: '.has-fin-docs',
    extFinancialUploadTableContainer: '.ext-financial-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var complaintId = this.model.get('complaintId');
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);
    var extDocuments = modelGet(this.model, 'correspondence.extDocuments', []);
    var headerBarData = {
      complainantName: correspondence.complainantName || '--',
      entityName: correspondence.entityName || '--'
    };

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'complainantName',
        label: 'Complainant Name',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/complaint/' + complaintId + '/dashboard',
            text: 'Home'
          },
          {
            text: 'Review Correspondence Response'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);

    this.intFileUploadTableView = new FileUploadView({
      collection: new FileUploadCollection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);

    this.extFileUploadView = new FileUploadView({
      collection: new FileUploadCollection(extDocuments),
      emptyText: 'No External Documents',
      isReadOnly: true
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var complaintId = this.model.get('complaintId');

    var l = Ladda.create(self.ui.submit[0]);
    l.start();
    SubmitReviewCorrespondenceTask(self.getFormData())
      .done(function() {
        Radio.channel('navigate').trigger('show:int:cs:complaint', complaintId);
      })
      .fail(function() {
        l.stop();
      });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    _.extend(formData, {
      taskId: this.model.get('taskId'),
      correspondenceId: this.model.get('correspondenceId')
    });

    return formData;
  }
});
