/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');

var Marionette = require('backbone.marionette');

var _ = require('underscore');

var tmplConSerComplaintFormIssueView = require('../templates/ConSerComplaintFormIssueView.hbs');
var toggleForm = require('common/util/toggleForm');

var parsleyUtil = require('common/util/parsleyUtil');

require("../styles/ConSerComplaintFormIssueView.css")

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormIssueView,

  className: 'conser-complaint-form-issue-view',
  
  ui: {
    complaintInformationForm: '.complaint-issue-form',
    checkboxes: 'input[type="checkbox"]',
    otherIssueBox: 'input[type="checkbox"][name="32"]',
    otherIssueForm: '.other-issue-form'
  },

  events: {
    'change @ui.otherIssueBox': 'onClickOther'
  },

  onClickOther: function() {
    toggleForm(this.ui.otherIssueForm, this.ui.otherIssueBox[0].checked)
  },

  onBeforeShow: function() {  
    Syphon.deserialize(this, this.model.toJSON());

    var checks = this.model.get('issues');
    if (checks) {
      _.forEach(this.ui.checkboxes, function (checkbox) {
        checkbox.checked = _.some(checks, function (issue) {
          return issue.ComplaintIssueID === checkbox.value;
        }) || checks.ComplaintIssueID === checkbox.value;
      });
    }

    toggleForm(this.ui.otherIssueForm, this.ui.otherIssueBox[0].checked)
  },

  validate: function(config) {
    var data = Syphon.serialize(this);
    var deferred = $.Deferred();

    this.ui.complaintInformationForm.parsley().reset();

    var isOneChecked = false;

    for (var issueItem in data) {
      if (data[issueItem]) {
        isOneChecked = true;
        break;
      }
    }

    if (!isOneChecked && config.type === 'submit') {
      return deferred.reject(
        'Atleast 1 option must be selected.'
      );
    }

    if (!this.ui.otherIssueBox[0].checked || config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.otherIssueForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.otherIssueForm);
    }
    
    return this.ui.complaintInformationForm.parsley().whenValidate();

  },

  getFormData: function() {
    var data = Syphon.serialize(this);
    var issues = {
      issues: [],
      otherIssue: data.otherIssue
    }
    for (var issueItem in data) {
      if (data[issueItem] && issueItem !== 'otherIssue') {
        issues.issues.push({issue : issueItem})
      }
    }

    return issues;
  }
});

// window.Parsley.addValidator('oneCheckbox', {
//   requirementType: 'string',
//   validateString: function(value, requirement) {
//     return $('input[name="checkboxGroup]:checked")').length >= 1    
//   },
//   messages: {
//     en: 'Please select at least 1'
//   }
// });
