/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');
var Syphon = require('backbone.syphon');

var tmplConSerComplaintFormEntityInformationView = require('../templates/ConSerComplaintFormEntityInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormEntityInformationView,

  className: 'conser-complaint-form-entity-information-view',

  ui: {
    hasContactedAttempt: 'input[name="hasContactedAttempt"]',
    hasContactedAttemptNoRadio: 'input[name="hasContactedAttempt"][value="0"]',
    hasContactedAttemptInfoForm: '.has-contacted-attempt-form',
    complaintEntityForm: '.complaint-entity-form',
    entityNameInput: 'input[name="EntityName"]'
  },

  events: {
    'change @ui.hasContactedAttempt': 'onChangeHasContactedAttempt'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: function() {
        var radios = [
          {
            target: '.has-contacted-attempt-form',
            radioGroup: '[name="hasContactedAttempt"]',
            valueToShow: '1'
          }
        ];

        return radios;
      }
    }
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    if (!this.model.get('hasContactedAttempt')) {
      this.ui.hasContactedAttemptNoRadio.prop('checked', true);
    }
  },

  validate: function(config) {

    if (config.type === 'save') {
      // // Turn off required field validation for these forms
      parsleyUtil.disableRequiredValidation(this.ui.complaintEntityForm);
      parsleyUtil.disableRequiredValidation(this.ui.hasContactedAttemptInfoForm);
      // // Always ensure that at least an entity name must be provided.
      // this.ui.entityNameInput.attr('data-parsley-required', 'true');
    } else {
      // // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.complaintEntityForm);
      parsleyUtil.enableRequiredValidation(this.ui.hasContactedAttemptInfoForm);
    }
    var formData = Syphon.serialize(this.ui.complaintEntityForm);
    var hasContactedAttempt = formData.hasContactedAttempt === '1';
    var validationPromises = [this.ui.complaintEntityForm.parsley().whenValidate()];
    if (hasContactedAttempt) {
      validationPromises.push(this.ui.hasContactedAttemptInfoForm.parsley().whenValidate());
    }

    return  $.when.apply(this, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    return formData;
  }
});
