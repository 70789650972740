/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var GetExternalUserDetails = require('../../services/GetExternalUserDetails');
var HandleKeyStoneAuthentication = require('../../services/HandleKeyStoneAuthentication');
var GetConfigurations = require('../../services/GetConfigurations');
var Session = require('psa-core/Session');
var Config = require('../../../config/Config');
var Cookie = require('js-cookie');

var preLoginUrl = window.location.href;
Cookie.set('preLoginUrl', Cookie.get('preLoginUrl') || preLoginUrl);

require('../styles/LoginView.css');

var tmplLoginView = require('../templates/KeyStoneLoginView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLoginView,

  className: 'ext-login-view',

  ui: {
    loginBtn: '.btn-login'
  },

  events: {
    'click @ui.loginBtn': 'handleLogin'
  },

  redirectToKeystone: function() {
    window.location.href = Config.getKeystoneLoginRedirectURL();
  },

  handleLogin: function(e) {
    e.preventDefault();
    var self = this;
    if (Cookie.get('KeystoneLogin')) {
      HandleKeyStoneAuthentication()
        .done(function(data) {
          NProgress.set(0.3);
          $.when(GetExternalUserDetails(), GetConfigurations())
            .done(function(profile, config) {
              NProgress.set(0.5);
              Session.user = profile;
              Session.config = config;
              Radio.channel('global').trigger('login:finish');
            })
            .fail(function(err) {
              NProgress.done();
              self.flashLoginErrorMessage(true);
            });
        })
        .fail(function(err) {
          NProgress.done();
          self.flashLoginErrorMessage(false);
          self.redirectToKeystone();
        });
      return;
    }
    self.redirectToKeystone();
    return;
  },

  templateHelpers: function() {
    return {
      register: Config.getKeystoneRegister,
      forgotUserName: Config.keystoneForgotUserName,
      forgotPassword: Config.getKeystoneForgotPassword,
      aboutKeystoneLogin: Config.getAboutKeystoneLogin,
      troubleRegistering: Config.getKeystoneTroubleRegistering
    };
  },

  flashLoginErrorMessage: function(isGeneral) {
    if (isGeneral) {
      this.ui.generalError.slideDown(300);
    } else {
      this.ui.loginError.slideDown(300);
    }
  }
});
