/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var Syphon = require('backbone.syphon');

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Session = require('psa-core/Session');

var parsleyUtil = require('common/util/parsleyUtil');
var SubmitExternalComplaint = require('../services/SubmitExternalComplaint')


require('../styles/ConSerComplaintFormConsumerServicesAuthorityView.css');

var tmplConSerComplaintFormConsumerServicesAuthorityView = require('../templates/ConSerComplaintFormConsumerServicesAuthorityView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormConsumerServicesAuthorityView,

  className: 'conser-complaint-form-consumer-services-authority-view',

  ui: {
    submitExternalComplaint: '.submit-new-complaint',
    validationErrors: '.validation-errors',
    authorityForm: '.authority-form'
  },

  events: {
    'click @ui.submitExternalComplaint': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      isExtEdit: this.options.viewMode === 'extEdit'
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  onClickSubmit: function(e) {
    var self = this;
    var complaintId = this.model.get('complaintId');
    var l = Ladda.create(e.currentTarget);
    l.start();
    this.sideNavView.validateAll({ type: 'submit' })
      .done(function() {
        var rawFormData = self.sideNavView.getFormData();
        var formData = {
          isDraft: '1', // 1 is open status
          externalUserId: Session.user.id,
          complaintId: complaintId,
          complainant: rawFormData.complaintFormYourInformation,
          serviceMember: rawFormData.complaintFormServiceMember,
          issue: rawFormData.complaintFormIssue,
          entity: rawFormData.complaintFormEntityInformation,
          information: rawFormData.complaintFormComplaintInformation,
          authority: rawFormData.complaintFormServicesAuthority
        };
        SubmitExternalComplaint(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:ext:cs:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  validate: function(config) {

    this.ui.validationErrors.hide();
    this.ui.authorityForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.authorityForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.authorityForm);
    }
    return this.ui.authorityForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    
    return formData;
  }
});
