/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSelfServiceDashboard: {
        $xmlns: 'http://dobs.pa.gov/cs/dashboard/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetSelfServiceDashboardResponse.Complaints.CS_Complaint',
      'GetSelfServiceDashboardResponse.Tasks.Tasks.NOTF_TASK_INSTANCE',
      'GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      complaints: [
        '$..Complaints.CS_Complaint',
        {
          id: '$.ID',
          caseNumber: '$.CaseNumber',
          entityName: '$.EntityName',
          modifiedDate: '$.ModifiedDate',
          complainantFirstName: '$.ComplainantFirstName',
          complainantLastName: '$.ComplainantLastName',
          complaintStatus: '$.Status',
          complaintType: '$.ComplaintType',
          createdDate: '$.CreatedDate'
        }
      ],
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          complaintId: '$..CSComplaintID',
          correspondenceId: '$..CSCorrespondenceID',
          taskTypeName: '$..TaskTypeName',
          dueDate: '$..DueDate',
          taskTypeName: '$..TaskTypeName'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$.GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      _.each(response.complaints, function(complaint) {
        complaint.modifiedDate = momentUtil.formatStr(complaint.modifiedDate, momentUtil.defaultInboundDateConfig);
        complaint.complaintantName = complaint.complainantLastName + ', ' + complaint.complainantFirstName;
        complaint.complaintType = complaint.complaintType || '--';
        complaint.entityName = complaint.entityName || '--';
        complaint.createdDate = moment(complaint.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
      });

      _.each(response.tasks, function(task) {
        var taskTypeName = _get(task, 'taskTypeName');
        var complaintId = _get(task, 'complaintId');
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return taskTypeName === type.name;
        });

        if (type === undefined) {
          type = '--';
        }
        task.type = type.description;

        if (complaintId) {
          var complaint = _.find(response.complaints, function(complaint) {
            return complaintId === complaint.id;
          });
          if (complaint) {
            task.caseNumber = complaint.caseNumber;
          }
        }

        task.dueDate = task.dueDate || '--';
      });

      // Group complaints by complaintStatus (crating two arrays based on draft and non-draft)
      var groupedComplaints = _.groupBy(response.complaints, complaint =>
        complaint.complaintStatus === 'Draft' ? 'draft' : 'non-draft'
      );

      response.complaints = groupedComplaints['non-draft'] || [];
      response.draftComplaints = groupedComplaints['draft'] || [];

      delete response.taskMetaData;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
