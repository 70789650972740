/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

// var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var Radio = require('backbone.radio');
// var _ = require('underscore');

var PanelGridView = require('common/views/PanelGridView');
var MomentPanelCellView = require('common/views/PanelGridView/MomentPanelCellView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');
var DropdownView = require('common/views/DropdownView');
// var AddressDisplayPanelCellView = require('common/views/PanelGridView/AddressDisplayPanelCellView');

require('../styles/DepExtDashboardView.css');
var tmplDepConSerExtDashboardView = require('../templates/ConSerExtDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepConSerExtDashboardView,

  className: 'con-ser-ext-dashboard-view container-fluid',

  ui: {
    pendingTaskWrapper: '.pending-task-wrapper',
    draftComplaintsWrapper: '.draft-complaints-wrapper',
    complaintsWrapper: '.complaints-wrapper',
    actionDropdown: '.action-dropdown'
  },

  regions: {
    pendingTasksWrapper: '@ui.pendingTaskWrapper',
    draftComplaintsWrapper: '@ui.draftComplaintsWrapper',
    complaintsWrapper: '@ui.complaintsWrapper',
    actionDropdown: '@ui.actionDropdown'
  },

  events: {},

  onBeforeShow: function() {
    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Actions',
          options: [
            {
              text: 'File New Complaint',
              onClick: function() {
                Radio.channel('navigate').trigger('show:ext:cs:new-complaint');
              }
            },
            {
              text: 'File New Investment Complaint',
              onClick: function() {
                Radio.channel('navigate').trigger('show:ext:cs:new-investor-complaint');
              }
            }
          ]
        })
      })
    );

    // --Grid that shows pending tasks--
    var pendingTasksView = new PanelGridView({
      columns: [
        {
          name: 'type',
          label: 'Task Name',
          width: 25,
          overflow: 'break-normal'
        },
        {
          name: 'caseNumber',
          label: 'Case Number',
          width: 30,
          overflow: 'truncate'
        },
        {
          name: 'deliveryDate',
          label: 'Assigned On',
          panelCell: MomentPanelCellView,
          width: 30
        },
        {
          name: '',
          label: '',
          width: 10,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/task/' + model.get('taskId');
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('tasks'), { pageSize: 5 }),
      emptyText: 'No Pending Tasks'
    });

    // --Grid that shows draft complaints--
    this.draftComplaintsCollection = new PageableCollection(this.model.get('draftComplaints'), { pageSize: 5 });
    var draftComplaintsView = new PanelGridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 30
        },
        {
          name: 'complaintType',
          label: 'Complaint Type',
          width: 20
        },
        {
          name: 'modifiedDate',
          label: 'Last Modified',
          panelCell: MomentPanelCellView,
          width: 30
        },
        {
          name: '',
          label: '',
          width: 20,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  var complaintType = model.get('complaintType');
                  if (complaintType === 'Standard') {
                    return '#ext/conser/complaint/' + model.get('id') + '/draft';
                  } else {
                    return '#ext/conser/investor-complaint/' + model.get('id') + '/draft';
                  }
                }
              }
            ]
          })
        }
      ],
      collection: this.draftComplaintsCollection,
      emptyText: 'No Draft Complaints'
    });

    // --Grid that shows current complaints--
    var complaintsView = new PanelGridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 30
        },
        {
          name: 'complaintType',
          label: 'Complaint Type',
          width: 18
        },
        {
          name: 'caseNumber',
          label: 'Case Number',
          width: 12
        },
        // Submitted On (Original submission date)
        {
          name: 'createdDate',
          label: 'Submitted On',
          panelCell: MomentPanelCellView,
          width: 15
        },
        // Status (Open, Closed, Closed-Duplicate)
        {
          name: 'complaintStatus',
          label: 'Status',
          width: 10,
          overflow: 'break-normal'
        },
        {
          name: '',
          label: '',
          width: 15,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Submit Information',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/conser/complaint/' + model.get('id') + '/new-correspondence';
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('complaints'), { pageSize: 5 }),
      emptyText: 'No Complaints'
    });

    this.showChildView('pendingTasksWrapper', pendingTasksView);
    this.showChildView('draftComplaintsWrapper', draftComplaintsView);
    this.showChildView('complaintsWrapper', complaintsView);
  }
});
