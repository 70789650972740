/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');

var tmplConSerComplaintSectionComplainantView = require('../templates/ConSerComplaintSectionComplainantView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintSectionComplainantView,

  className: 'con-ser-complaint-section-complainant-view',

  ui: {
    complainantForm: '.complainant-form',
    addressForm: '.address-form-container',
    isComplainant: 'input[name="isComplainant"]',
    isComplainantNoRadio: 'input[name="isComplainant"][value="0"]',
    isComplainantInfoForm: '.is-not-complainant-form',
    isVeteranServiceMember: '[name="isVeteranServiceMember"]',
    serviceMemberStatus: '[name="serviceMemberStatus"]'
  },

  events: {
    'change @ui.isVeteranServiceMember': 'defaultToVeteran'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: function() {
        return [
          {
            target: '.service-description',
            radioGroup: '[name="isVeteranServiceMember"]',
            valueToShow: '1'
          },
          {
            target: '.is-not-complainant-form',
            radioGroup: '[name="isComplainant"]',
            valueToShow: '0'
          }
        ];
      }
    }
  },

  regions: {
    addressForm: '@ui.addressForm'
  },

  defaultToVeteran: function() {
    if (this.ui.isVeteranServiceMember[0].checked) {
      if (this.model.get('complaint').serviceMemberStatus === '1') {
        this.ui.serviceMemberStatus.val(2);
      }
    }
  },

  onBeforeShow: function() {
    var complaint = this.model.get('complaint') || {};

    this.addressForm = new AddressView({
      model: new Backbone.Model(complaint),
      horizontal: true,
      isRequired: false,

      startingCountry: complaint.country,
      startingPostalCode: complaint.postalCode,
      startingCounty: complaint.county
    });

    this.showChildView('addressForm', this.addressForm);

    Syphon.deserialize(this, complaint);

    if (!this.model.get('complaint')) {
      this.addressForm.setState('PA');
    }
  },

  validate: function() {
    return this.ui.complainantForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
